
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'
import moment from 'moment'
import { useToast } from 'vue-toastification'
import ModalStatus from '@/main/components/Modals/ModalStatus.vue'

export default defineComponent({
  name: 'Rekaman Webinar Mentor Area',
  setup() {
    const toast = useToast()
    return { toast }
  },
  components: {
    ModalStatus,
  },
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      isLoading: false,
      webinarRecords: [] as any,
      tasks: [] as any,
      showDetailWebinarRecord: false,
      showModalWebinarRecord: false,
      showDeleteWebinarRecord: false,
      formWebinarRecord: {
        name: '' as string,
        link: '' as string,
        live_at: '' as string,
        expired_at: '' as string,
        password: '' as string,
        webinar_id: 0 as number,
      },
      webinarRecordId: 0 as number,
      webinarRecord: {} as any,
      linkType: 'embed' as string,
      passwordLinkStatus: false,
      errorDeadline: false,
      showModalStatus: false,
      modalStatus: {
        status: '' as string,
        title: '' as string,
        description: '' as string,
      },
      linkEmbed: '' as string,
      // webinars: [] as any
    }
  },
  mounted() {
    this.getWebinarRecord()
    // ClassroomModel.listWebinarByClassLevel(this.classroomCode + '1')
    // .then(res => {
    //   this.webinars = res.data
    // })
    // .catch(err => {
    //   console.log(err)
    // })
  },
  computed: {
    checkForm() {
      if (
        this.formWebinarRecord.name &&
        this.formWebinarRecord.link &&
        this.formWebinarRecord.live_at
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async getWebinarRecord() {
      this.isLoading = true
      await ClassroomModel.listWebinarRecordByClassLevelCode(
        this.classroomCode + '1'
      )
        .then(res => {
          this.isLoading = false
          if (res.data.length > 0) {
            this.webinarRecords = [] as any
            res.data.forEach((el: any, i: number) => {
              this.webinarRecords.push({
                ...el,
                no: i + 1,
              })
            })
          }
        })
        .catch(err => {
          this.$router.push('/mentor/classroom')
          this.isLoading = false
        })
    },
    isIframe(link: string) {
      if (link.includes('iframe')) {
        return true
      } else {
        return false
      }
    },
    formatDate(date: string) {
      return moment(date)
        .locale('id')
        .format('dddd, DD MMMM YYYY HH:mm')
    },
    openDetailWebinarRecord(data: any) {
      this.webinarRecord = data
      if (data?.link.includes('iframe')) {
        const embed = data?.link
        const parser: any = new DOMParser()
        const parsedIframe = parser.parseFromString(embed, 'text/html')
        const iFrame = parsedIframe.getElementsByTagName('iframe')
        this.linkEmbed = iFrame[0].src
      }
      this.showDetailWebinarRecord = true
    },
    closDetailWebinarRecord() {
      this.webinarRecord = {}
      this.showDetailWebinarRecord = false
    },
    addNewWebinarRecord() {
      this.webinarRecordId = 0
      this.formWebinarRecord.name = ''
      this.formWebinarRecord.live_at = ''
      this.formWebinarRecord.link = ''
      this.formWebinarRecord.password = ''
      this.formWebinarRecord.expired_at = ''
      this.formWebinarRecord.webinar_id = 0
      this.linkType = 'embed'
      this.showModalWebinarRecord = true
    },
    updateWebinarRecord(data: any) {
      this.webinarRecordId = data.id
      this.formWebinarRecord.name = data.name
      this.formWebinarRecord.live_at = data.live_at
      this.formWebinarRecord.link = data.link
      this.formWebinarRecord.password = data.password
      this.formWebinarRecord.expired_at = data.expired_at
      this.formWebinarRecord.webinar_id = data.webinar_id
      if (data.link.includes('</iframe>')) {
        this.linkType = 'embed'
      } else {
        this.linkType = 'link'
      }
      this.showModalWebinarRecord = true
      this.errorDeadline = false
    },
    closeModalWebinarRecord() {
      this.webinarRecord = {}
      this.webinarRecordId = 0
      this.formWebinarRecord.name = ''
      this.formWebinarRecord.live_at = ''
      this.formWebinarRecord.link = ''
      this.formWebinarRecord.password = ''
      this.formWebinarRecord.expired_at = ''
      this.formWebinarRecord.webinar_id = 0
      this.linkType = 'embed'
      this.showModalWebinarRecord = false
      this.errorDeadline = false
    },
    async submitWebinarRecord() {
      if (this.checkForm) {
        this.formWebinarRecord.live_at = moment(
          this.formWebinarRecord.live_at
        ).format('YYYY-MM-DD HH:mm:00')
        if (this.formWebinarRecord.expired_at != '') {
          this.formWebinarRecord.expired_at = moment(
            this.formWebinarRecord.expired_at
          ).format('YYYY-MM-DD HH:mm:00')
        }
        const payload: any = {
          name: this.formWebinarRecord.name,
          link: this.formWebinarRecord.link,
          live_at: this.formWebinarRecord.live_at,
        }
        if (this.webinarRecordId == 0) {
          await ClassroomModel.createWebinarRecordMentor(
            this.classroomCode,
            payload
          )
            .then(res => {
              if (res.success) {
                this.closeModalWebinarRecord()
                this.getWebinarRecord()
                this.toast.success('Berhasil tambah rekaman webinar')
              } else {
                this.toast.error('Terjadi kesalahan')
              }
            })
            .catch(err => {
              this.closeModalWebinarRecord()
              this.toast.error(err.response.data.error[0].message)
            })
        } else {
          await ClassroomModel.updateWebinarRecordMentor(
            this.webinarRecordId,
            payload
          )
            .then(res => {
              if (res.success) {
                this.closeModalWebinarRecord()
                this.getWebinarRecord()
                this.toast.success('Berhasil update rekaman webinar')
              } else {
                this.toast.error('Terjadi kesalahan')
              }
            })
            .catch(err => {
              this.closeModalWebinarRecord()
              this.toast.error(err.response.data.error[0].message)
            })
        }
      }
    },
    showModalDeleteWebinarRecord(data: any) {
      this.webinarRecord = data
      this.webinarRecordId = data.id
      this.showDeleteWebinarRecord = true
    },
    closeDeleteWebinarRecord() {
      this.webinarRecord = {}
      this.webinarRecordId = 0
      this.showDeleteWebinarRecord = false
    },
    async deleteWebinarRecord() {
      await ClassroomModel.deleteWebinarRecordMentor(this.webinarRecordId)
        .then(res => {
          this.closeDeleteWebinarRecord()
          if (res.success) {
            this.getWebinarRecord()
            this.toast.success('Berhasil menghapus rekaman webinar')
          } else {
            this.toast.error('Terjadi kesalahan')
            this.closeDeleteWebinarRecord()
          }
        })
        .catch(err => {
          this.closeDeleteWebinarRecord()
          this.toast.error(err.response.data.errors[0].message)
        })
    },
    togglePasswordLink() {
      this.passwordLinkStatus = !this.passwordLinkStatus
    },
    closeDetailWebinarRecord() {
      this.showDetailWebinarRecord = false
    },
    closeModalStatus() {
      this.showModalStatus = false
    },
    copyPasswordWebinar() {
      const password = this.$refs.password as HTMLInputElement
      try {
        password.setAttribute('type', 'text')
        password.select()
        document.execCommand('copy')
        this.toast.success('Berhasil disalin')
      } catch (err) {
        this.toast.error('Oops, unable to copy')
      }
      password.setAttribute('type', 'hidden')
    },
  },
})
