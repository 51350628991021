<template>
  <div class="w-full md:mx-2 rounded">
    <!-- Tab Contents -->
    <div class="mb-10 bg-white">
      <div
        class="flex justify-between border-b border-gray-300 mb-3 items-center px-2"
      >
        <p class="p-3 font-desain text-[#212121] font-semibold text-lg">
          List Rekaman Webinar
        </p>
        <button
          class="bg-[#00446F] text-white px-4 py-2 rounded-full font-solusi font-semibold text-xs h-fit-content flex items-center outline-none focus:outline-none"
          @click="addNewWebinarRecord()"
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            class="mr-1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2143 8.95254H9.45238V13.7144C9.45238 14.2383 9.02381 14.6668 8.5 14.6668C7.97619 14.6668 7.54762 14.2383 7.54762 13.7144V8.95254H2.78572C2.26191 8.95254 1.83334 8.52397 1.83334 8.00016C1.83334 7.47635 2.26191 7.04778 2.78572 7.04778H7.54762V2.28588C7.54762 1.76207 7.97619 1.3335 8.5 1.3335C9.02381 1.3335 9.45238 1.76207 9.45238 2.28588V7.04778H14.2143C14.7381 7.04778 15.1667 7.47635 15.1667 8.00016C15.1667 8.52397 14.7381 8.95254 14.2143 8.95254Z"
              fill="currentColor"
            />
          </svg>
          Tambah Rekaman Webinar
        </button>
      </div>
      <div class="w-full m-auto">
        <DataTable
          :value="webinarRecords"
          :paginator="true"
          :rows="10"
          dataKey="id"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50]"
          class="p-datatable-sm font-solusi text-sm"
          currentPageReportTemplate="Menampilkan {first} hingga {last} dari {totalRecords} rekaman webinar"
          responsiveLayout="scroll"
          :loading="isLoading"
          :rowHover="true"
        >
          <template #empty>
            <div
              class="text-center font-semibold text-accent font-solusi text-base"
            >
              Belum ada rekaman webinar
            </div>
          </template>
          <template #loading>
            <div class="text-center font-semibold font-solusi text-base">
              Loading data rekaman webinar. Please wait.
            </div>
          </template>
          <Column
            field="name"
            header="No."
            headerClass="w-12 text-[#212121]"
            bodyClass="font-solusi font-semibold text-sm text-[#383838]"
          >
            <template #body="{data}"> {{ data.no }}. </template>
          </Column>
          <Column
            field="name"
            header="Nama Rekaman Webinar"
            bodyClass="font-solusi font-medium text-sm text-[#383838]"
          >
            <template #body="{data}">
              {{ data.name }}
            </template>
          </Column>

          <Column
            field="started_at"
            header="Tanggal Berlangsung Webinar"
            bodyClass="font-solusi font-medium text-sm text-[#383838]"
          >
            <template #body="{data}">
              {{ formatDate(data.live_at) }} WIB
            </template>
          </Column>

          <!-- <Column
            field="expired_at"
            header="Deadline"
            bodyClass="font-solusi font-medium text-sm text-[#383838]"
          >
            <template #body="{data}">
              {{ formatDate(data.expired_at) }}
            </template>
          </Column> -->

          <!-- <Column
            field="name"
            header="Status"
            headerClass="w-28"
            bodyClass="font-solusi font-medium text-sm"
          >
            <template #body="{data}">
              <span
                v-if="data.is_active"
                class="font-solusi text-xs font-semibold inline-block py-1 px-2 rounded text-green-600 bg-green-200 last:mr-0 mr-1"
              >
                Aktif
              </span>
              <span
                v-else
                class="font-solusi text-xs font-semibold inline-block py-1 px-2 rounded text-red-600 bg-red-200 last:mr-0 mr-1"
              >
                Tidak aktif
              </span>
            </template>
          </Column> -->

          <Column
            field="name"
            header="Aksi"
            headerClass="w-64"
            bodyClass="font-solusi font-medium text-sm flex w-auto"
          >
            <template #body="{data}">
              <button
                v-tooltip.top="'Preview Rekaman Webinar'"
                @click="openDetailWebinarRecord(data)"
                class="px-3 py-2 text-center rounded-full flex items-center btn-show mb-1 mr-1 outline-none focus:outline-none"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  class=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00009 13.3337C2.97513 13.3337 0.769722 8.5199 0.666682 8.33366C0.586056 8.18866 0.586161 7.812 0.666786 7.66699C0.769911 7.48074 2.97513 2.66699 8.00009 2.66699C13.025 2.66699 15.2304 7.81409 15.3334 8.00033C15.4141 8.14533 15.4141 8.18865 15.3334 8.33366C15.2303 8.51991 13.025 13.3337 8.00009 13.3337ZM1.66677 8.00033C2.2374 8.86532 4.17753 12.3337 8.00009 12.3337C11.8345 12.3337 13.7647 8.86645 14.3335 8.00021C13.7628 7.13522 11.8226 3.66699 8.00009 3.66699C4.16569 3.66699 2.23557 7.13409 1.66677 8.00033ZM8.00009 10.8802C6.41196 10.8802 5.12009 9.58834 5.12009 8.00021C5.12009 6.41208 6.41196 5.12021 8.00009 5.12021C9.58821 5.12021 10.8801 6.41208 10.8801 8.00021C10.8801 9.58834 9.58821 10.8802 8.00009 10.8802ZM8.00009 6.08021C6.94134 6.08021 6.08009 6.94146 6.08009 8.00021C6.08009 9.05896 6.94134 9.92021 8.00009 9.92021C9.05884 9.92021 9.92009 9.05896 9.92009 8.00021C9.92009 6.94146 9.05884 6.08021 8.00009 6.08021Z"
                    fill="currentColor"
                  />
                </svg>
                <span
                  class="font-solusi text-xs font-semibold hidden md:inline-block ml-1"
                  >Preview</span
                >
              </button>
              <button
                v-tooltip.top="'Edit Rekaman Webinar'"
                @click="updateWebinarRecord(data)"
                class="px-3 py-2 text-center rounded-full flex items-center btn-edit mb-1 mr-1 outline-none focus:outline-none"
              >
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  class=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.79279 3.33325L13.1668 6.70725L6.54135 13.3326C6.35676 13.5172 6.1272 13.6504 5.87536 13.7191L2.4649 14.6493C2.09165 14.751 1.74917 14.4086 1.85096 14.0353L2.78109 10.6249C2.84977 10.373 2.98299 10.1434 3.16757 9.95887L9.79279 3.33325ZM14.5202 1.97998C15.4519 2.91162 15.4519 4.42211 14.5202 5.35375L13.8735 5.99992L10.4995 2.62658L11.1465 1.97998C12.0781 1.04834 13.5886 1.04834 14.5202 1.97998Z"
                    fill="currentColor"
                  />
                </svg>
                <span
                  class="font-solusi text-xs font-semibold hidden md:inline-block ml-1"
                  >Ubah</span
                >
              </button>
              <button
                v-tooltip.top="'Hapus Rekaman Webinar'"
                @click="showModalDeleteWebinarRecord(data)"
                class="px-3 py-2 text-center rounded-full flex items-center btn-delete mb-1 mr-1 outline-none focus:outline-none"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  class=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.3333 4C14.3333 4.34189 14.076 4.62367 13.7444 4.66218L13.6667 4.66667H13.1035L12.2826 13.0128C12.1903 13.9513 11.4011 14.6667 10.4581 14.6667H5.54196C4.59894 14.6667 3.80974 13.9513 3.71743 13.0128L2.8965 4.66667H2.33334C1.96515 4.66667 1.66667 4.36819 1.66667 4C1.66667 3.63181 1.96515 3.33333 2.33334 3.33333H5.66667C5.66667 2.04467 6.71134 1 8 1C9.28867 1 10.3333 2.04467 10.3333 3.33333H13.6667C14.0349 3.33333 14.3333 3.63181 14.3333 4ZM9.5 6.16667C9.24687 6.16667 9.03768 6.35477 9.00457 6.59882L9 6.66667V11.3333L9.00457 11.4012C9.03768 11.6452 9.24687 11.8333 9.5 11.8333C9.75313 11.8333 9.96233 11.6452 9.99544 11.4012L10 11.3333V6.66667L9.99544 6.59882C9.96233 6.35477 9.75313 6.16667 9.5 6.16667ZM6.5 6.16667C6.24687 6.16667 6.03768 6.35477 6.00457 6.59882L6 6.66667V11.3333L6.00457 11.4012C6.03768 11.6452 6.24687 11.8333 6.5 11.8333C6.75314 11.8333 6.96233 11.6452 6.99544 11.4012L7 11.3333V6.66667L6.99544 6.59882C6.96233 6.35477 6.75314 6.16667 6.5 6.16667ZM8 2.33333C7.44772 2.33333 7 2.78105 7 3.33333H9C9 2.78105 8.55229 2.33333 8 2.33333Z"
                    fill="currentColor"
                  />
                </svg>
                <span
                  class="font-solusi text-xs font-semibold hidden md:inline-block ml-1"
                  >Hapus</span
                >
              </button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="showDetailWebinarRecord"
        class="opacity-50 fixed inset-0 z-50 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div
        v-if="showDetailWebinarRecord"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex"
        style="z-index:51"
      >
        <div
          class="relative w-full my-6 mx-4 md:mx-auto"
          :class="isIframe(webinarRecord?.link) ? 'md:w-1/2' : 'md:w-1/3'"
        >
          <!--content-->
          <div
            v-click-away="closeDetailWebinarRecord"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <!--header-->
            <div class="flex items-start justify-between p-3 pb-0 rounded-t">
              <span class="text-xl font-semibold text-black080 font-desain">{{
                webinarRecord?.name
              }}</span>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeDetailWebinarRecord()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div class="py-3 px-6 flex-auto overflow-x-hiden overflow-y-auto">
              <div v-if="isIframe(webinarRecord?.link)">
                <iframe
                  class="w-full h-[50vh]"
                  :src="linkEmbed"
                  :title="webinarRecord.name"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div
                v-else
                class="flex flex-col items-center justify-center my-6"
              >
                <a
                  :href="webinarRecord?.link"
                  target="_blank"
                  class="font-solusi text-[#FFFFFF] rounded-full bg-[#00446F] text-sm font-semibold py-2 px-8 mb-2"
                >
                  Buka Link
                </a>
                <div
                  v-if="webinarRecord?.password"
                  class="flex items-center bg-[#FFFFFF] rounded-md mb-2 px-3 py-1"
                >
                  <span
                    class="font-solusi text-sm font-semibold text-[#0A72ED] "
                    >password: {{ webinarRecord?.password }}</span
                  >
                  <input
                    type="hidden"
                    ref="password"
                    :value="webinarRecord?.password"
                  />
                  <button
                    @click="copyPasswordWebinar()"
                    v-tooltip="{
                      content: 'Salin',
                    }"
                    class="ml-1 font-solusi text-[#0A72ED] text-base font-semibold outline-none focus:outline-none flex items-center"
                  >
                    <svg
                      class="ml-1 w-4 h-4"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 4H3C2.44813 4 2 4.44813 2 5V14C2 14.5519 2.44813 15 3 15H11C11.5519 15 12 14.5519 12 14V13H4V4Z"
                        fill="#0A72ED"
                      />
                      <path d="M11 1V5H15L11 1Z" fill="#0A72ED" />
                      <path
                        d="M10 6V1H6C5.44813 1 5 1.44813 5 2V12H14C14.5519 12 15 11.5519 15 11V6H10Z"
                        fill="#0A72ED"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showModalWebinarRecord"
        class="opacity-50 fixed inset-0 z-50 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div
        v-if="showModalWebinarRecord"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[85vh]"
          >
            <!--header-->
            <div class="flex items-start justify-between p-3 pb-0 rounded-t">
              <h3 class="text-xl font-semibold text-[#212121] font-desain">
                {{
                  webinarRecordId > 0
                    ? 'Ubah Rekaman Webinar'
                    : 'Tambah Rekaman Webinar'
                }}
              </h3>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeModalWebinarRecord()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <form
              class="px-6 py-3 flex-auto overflow-x-hiden overflow-y-auto scroll-custom"
              autocomplete="off"
            >
              <div class="mb-3">
                <label
                  for="name"
                  class="font-solusi block text-sm font-medium mb-1"
                  >Nama Rekaman Webinar</label
                >
                <textarea
                  class="w-full font-solusi appearance-none border rounded w-full px-3 py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                  placeholder="Tuliskan nama rekaman webinar"
                  rows="2"
                  v-model="formWebinarRecord.name"
                ></textarea>
                <!-- <input
                  class="w-full font-solusi appearance-none border rounded w-full px-3 py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                  type="text"
                  placeholder="Tuliskan nama rekaman webinar"
                  v-model="formWebinarRecord.name"
                /> -->
              </div>
              <div class="mb-3">
                <label class="font-solusi text-sm mb-1">
                  Tanggal Webinar Berlangsung
                </label>
                <v-date-picker
                  v-model="formWebinarRecord.live_at"
                  mode="datetime"
                  is24hr
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="font-solusi text-sm px-3 py-2 rounded focus:outline-none w-full"
                      :class="
                        errorDeadline ? 'border-2 border-red-500' : 'border'
                      "
                      placeholder="Tanggal webinar berlangsung"
                      :value="inputValue"
                      v-on="inputEvents"
                      name="webinar-live"
                      id="webinar-live"
                      autocomplete="off"
                    />
                  </template>
                </v-date-picker>
                <!-- <p class="text-xs text-[#DE1306] font-solusi font-semibold">
                  Dedline tugas harus lebih besar dari tanggal dimulai tugas
                </p> -->
              </div>
              <div class="mb-0">
                <label
                  for="name"
                  class="font-solusi block text-sm font-medium mb-1"
                  >Link Rekaman Webinar</label
                >
                <div class="mb-2">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      class="form-radio"
                      name="linkType"
                      value="embed"
                      v-model="linkType"
                    />
                    <span
                      class="ml-2 font-solusi text-sm text-[#212121] cursor-pointer"
                      >Embed YouTube</span
                    >
                  </label>
                  <label class="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      class="form-radio"
                      name="linkType"
                      value="link"
                      v-model="linkType"
                    />
                    <span
                      class="ml-2 font-solusi text-sm text-[#212121] cursor-pointer"
                      >Link</span
                    >
                  </label>
                </div>
                <textarea
                  class="w-full font-solusi appearance-none border rounded w-full px-3 py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                  id="link"
                  :placeholder="
                    linkType == 'embed'
                      ? 'Masukkan link embed YouTube'
                      : 'Masukkan link'
                  "
                  rows="3"
                  v-model="formWebinarRecord.link"
                ></textarea>
                <div v-if="linkType == 'embed'">
                  <div
                    id="link"
                    class="font-solusi text-xs text-gray-600 text-left block mb-1"
                  >
                    Cara embed video bisa lihat
                    <a
                      href="https://support.google.com/youtube/answer/171780?hl=id"
                      target="_blank"
                      class="text-xs font-solusi text-blue-500 hover:text-blue-600 font-semibold"
                      >disini</a
                    >
                  </div>
                  <div
                    id="link"
                    class="font-solusi text-xs text-[#DE1306] text-left"
                    style="overflow-wrap: break-word;"
                  >
                    {{ `Contoh link embed:
                    <iframe
                      width="550"
                      height="315"
                      src="https://www.youtube.com/embed/fwD96blnYZ8"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe
                    >` }}
                  </div>
                </div>
                <div v-else-if="linkType == 'link'">
                  <div class="font-solusi text-xs text-[#DE1306] text-left">
                    Diawali dengan https:// atau http://
                  </div>
                </div>
              </div>
              <!-- <div class="mb-3">
                <label
                  for="name"
                  class="font-solusi block text-sm font-medium mb-1"
                  >Password <small class="text-xs font-solusi text-gray-500">(optional)</small> </label
                >
                <div class="relative w-full inline-flex">
                  <input
                    id="password-link"
                    class="w-full font-solusi appearance-none border rounded w-full pl-3 pr-8 py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                    :type="[passwordLinkStatus ? 'text' : 'password']"
                    placeholder="Tuliskan password link"
                    v-model="formWebinarRecord.password"
                    autocomplete="off"
                    name="password-link"
                  />
                  <div class="p-2 z-10 cursor-pointer absolute top-0 right-0" @click="togglePasswordLink">
                    <svg
                      v-if="passwordLinkStatus"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.4412 14.5821C16.9013 13.5544 18.0356 12.1014 18.634 10.3638C18.6783 10.2358 18.6783 10.0966 18.634 9.96937C17.4245 6.45558 14.0222 4.09512 10.1668 4.09512C8.66165 4.09512 7.22835 4.45876 5.96514 5.10619L2.70351 1.84456C2.46635 1.6074 2.08217 1.6074 1.84501 1.84456C1.60785 2.08172 1.60785 2.4659 1.84501 2.70306L4.89253 5.75058C3.43246 6.77825 2.29812 8.23126 1.69971 9.96882C1.65544 10.0969 1.65544 10.236 1.69971 10.3633C3.44911 15.4471 9.52341 17.7088 14.3678 15.2257L17.6294 18.4873C17.8666 18.7245 18.2508 18.7245 18.4879 18.4873C18.7251 18.2502 18.7251 17.866 18.4879 17.6288L15.4412 14.5821ZM10.167 5.30939C13.4311 5.30939 16.3157 7.25248 17.4143 10.1663C16.8602 11.6359 15.8507 12.8581 14.5686 13.7086L13.133 12.2731C13.5575 11.6778 13.8097 10.9521 13.8097 10.1664C13.8097 8.15765 12.1757 6.52366 10.167 6.52366C9.38122 6.52366 8.65552 6.77584 8.05949 7.20035L6.8745 6.01536C7.88084 5.56161 8.9986 5.30943 10.1671 5.30943L10.167 5.30939ZM8.08236 8.94015L11.393 12.2508C11.0317 12.4642 10.6159 12.5946 10.1669 12.5946C8.82773 12.5946 7.73841 11.5053 7.73841 10.1662C7.73841 9.71713 7.86884 9.30134 8.08228 8.94007L8.08236 8.94015ZM8.94086 8.08164C9.30213 7.8682 9.71793 7.73777 10.167 7.73777C11.5061 7.73777 12.5954 8.8271 12.5954 10.1662C12.5954 10.6153 12.465 11.0311 12.2515 11.3923L8.94086 8.08164ZM2.91948 10.1663C3.47364 8.69671 4.48313 7.47453 5.76524 6.62396L7.20082 8.05954C6.77631 8.6548 6.52413 9.38049 6.52413 10.1662C6.52413 12.1749 8.15813 13.8089 10.1668 13.8089C10.9526 13.8089 11.6783 13.5568 12.2743 13.1322L13.4593 14.3172C9.36837 16.1591 4.46712 14.2714 2.91938 10.1662L2.91948 10.1663Z"
                        fill="#212121"
                      />
                    </svg>
                    <svg
                      v-else
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0001 16.6668C3.71892 16.6668 0.962166 10.6496 0.833366 10.4168C0.732584 10.2355 0.732714 9.7647 0.833496 9.58344C0.962402 9.35062 3.71892 3.33344 10.0001 3.33344C16.2813 3.33344 19.038 9.7673 19.1668 10.0001C19.2676 10.1814 19.2676 10.2355 19.1668 10.4168C19.0379 10.6496 16.2813 16.6668 10.0001 16.6668ZM2.08348 10.0001C2.79676 11.0813 5.22192 15.4168 10.0001 15.4168C14.7931 15.4168 17.2058 11.0828 17.9168 9.99996C17.2036 8.91872 14.7783 4.58344 10.0001 4.58344C5.20712 4.58344 2.79448 8.9173 2.08348 10.0001ZM10.0001 13.6C8.01496 13.6 6.40012 11.9851 6.40012 9.99996C6.40012 8.0148 8.01496 6.39996 10.0001 6.39996C11.9853 6.39996 13.6001 8.0148 13.6001 9.99996C13.6001 11.9851 11.9853 13.6 10.0001 13.6ZM10.0001 7.59996C8.67668 7.59996 7.60012 8.67652 7.60012 9.99996C7.60012 11.3234 8.67668 12.4 10.0001 12.4C11.3236 12.4 12.4001 11.3234 12.4001 9.99996C12.4001 8.67652 11.3236 7.59996 10.0001 7.59996Z"
                        fill="#212121"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="mb-0">
                <label class="font-solusi text-sm mb-1">
                  Tanggal Kadaluarsa Rekaman Webinar <small class="text-xs font-solusi text-gray-500">(optional)</small>
                </label>
                <v-date-picker
                  v-model="formWebinarRecord.expired_at"
                  mode="datetime"
                  is24hr
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="font-solusi text-sm px-3 py-2 rounded focus:outline-none w-full"
                      :class="
                        errorDeadline ? 'border-2 border-red-500' : 'border'
                      "
                      placeholder="Tanggal kadaluarsa link"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </v-date-picker>
              </div> -->
            </form>
            <div class="flex justify-center p-3">
              <button
                class="px-16 py-1 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none"
                :class="
                  checkForm
                    ? 'bg-[#00446F] text-white'
                    : 'border border-gray-100 text-[#9E9E9E] cursor-not-allowed'
                "
                @click="submitWebinarRecord"
                :disabled="!checkForm"
              >
                {{ webinarRecordId > 0 ? 'Ubah' : 'Tambah' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showDeleteWebinarRecord"
        class="opacity-50 fixed inset-0 z-50 bg-black"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="showDeleteWebinarRecord"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex"
        style="z-index:51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <!--content-->
          <div
            v-click-away="closeDeleteWebinarRecord"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <!--header-->
            <div class="flex items-start justify-end p-3 pb-0 rounded-t">
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeDeleteWebinarRecord()"
              >
                <svg
                  class="fill-current text-[#383838]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  ></path>
                </svg>
              </div>
            </div>
            <!--body-->
            <div
              class="pt-0 pb-6 px-6 flex-auto overflow-x-hiden overflow-y-auto"
            >
              <img
                src="/assets/images/mentor/illustration-delete.svg"
                class="w-1/2 m-auto"
                alt=""
              />
              <p
                class="font-desain font-semibold my-3 text-center text-[#212121] text-xl"
              >
                Hapus Rekaman Webinar
              </p>
              <p
                class="font-solusi font-medium text-base text-center text-[#383838]"
              >
                Yakin Anda ingin menghapus
                <span class="font-solusi font-semibold text-base">{{
                  webinarRecord?.name
                }}</span
                >?
              </p>
              <div class="flex justify-evenly mt-8">
                <button
                  @click="closeDeleteWebinarRecord"
                  class="bg-[#00446F] px-9 py-2 text-white font-solusi font-semibold rounded-full outline-none focus:outline-none"
                >
                  Tidak
                </button>
                <button
                  @click="deleteWebinarRecord"
                  class="px-9 py-2 font-solusi font-semibold rounded-full outline-none focus:outline-none border-dark-blue text-[#00446F]"
                >
                  Ya, saya yakin
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <ModalStatus
      :show="showModalStatus"
      :status="modalStatus.status"
      :title="modalStatus.title"
      :description="modalStatus.description"
      :close="closeModalStatus"
    />
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import ClassroomModel from '@/models/ClassroomModel'
import moment from 'moment'
import { useToast } from 'vue-toastification'
import ModalStatus from '@/main/components/Modals/ModalStatus.vue'

export default defineComponent({
  name: 'Rekaman Webinar Mentor Area',
  setup() {
    const toast = useToast()
    return { toast }
  },
  components: {
    ModalStatus,
  },
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      isLoading: false,
      webinarRecords: [] as any,
      tasks: [] as any,
      showDetailWebinarRecord: false,
      showModalWebinarRecord: false,
      showDeleteWebinarRecord: false,
      formWebinarRecord: {
        name: '' as string,
        link: '' as string,
        live_at: '' as string,
        expired_at: '' as string,
        password: '' as string,
        webinar_id: 0 as number,
      },
      webinarRecordId: 0 as number,
      webinarRecord: {} as any,
      linkType: 'embed' as string,
      passwordLinkStatus: false,
      errorDeadline: false,
      showModalStatus: false,
      modalStatus: {
        status: '' as string,
        title: '' as string,
        description: '' as string,
      },
      linkEmbed: '' as string,
      // webinars: [] as any
    }
  },
  mounted() {
    this.getWebinarRecord()
    // ClassroomModel.listWebinarByClassLevel(this.classroomCode + '1')
    // .then(res => {
    //   this.webinars = res.data
    // })
    // .catch(err => {
    //   console.log(err)
    // })
  },
  computed: {
    checkForm() {
      if (
        this.formWebinarRecord.name &&
        this.formWebinarRecord.link &&
        this.formWebinarRecord.live_at
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async getWebinarRecord() {
      this.isLoading = true
      await ClassroomModel.listWebinarRecordByClassLevelCode(
        this.classroomCode + '1'
      )
        .then(res => {
          this.isLoading = false
          if (res.data.length > 0) {
            this.webinarRecords = [] as any
            res.data.forEach((el: any, i: number) => {
              this.webinarRecords.push({
                ...el,
                no: i + 1,
              })
            })
          }
        })
        .catch(err => {
          this.$router.push('/mentor/classroom')
          this.isLoading = false
        })
    },
    isIframe(link: string) {
      if (link.includes('iframe')) {
        return true
      } else {
        return false
      }
    },
    formatDate(date: string) {
      return moment(date)
        .locale('id')
        .format('dddd, DD MMMM YYYY HH:mm')
    },
    openDetailWebinarRecord(data: any) {
      this.webinarRecord = data
      if (data?.link.includes('iframe')) {
        const embed = data?.link
        const parser: any = new DOMParser()
        const parsedIframe = parser.parseFromString(embed, 'text/html')
        const iFrame = parsedIframe.getElementsByTagName('iframe')
        this.linkEmbed = iFrame[0].src
      }
      this.showDetailWebinarRecord = true
    },
    closDetailWebinarRecord() {
      this.webinarRecord = {}
      this.showDetailWebinarRecord = false
    },
    addNewWebinarRecord() {
      this.webinarRecordId = 0
      this.formWebinarRecord.name = ''
      this.formWebinarRecord.live_at = ''
      this.formWebinarRecord.link = ''
      this.formWebinarRecord.password = ''
      this.formWebinarRecord.expired_at = ''
      this.formWebinarRecord.webinar_id = 0
      this.linkType = 'embed'
      this.showModalWebinarRecord = true
    },
    updateWebinarRecord(data: any) {
      this.webinarRecordId = data.id
      this.formWebinarRecord.name = data.name
      this.formWebinarRecord.live_at = data.live_at
      this.formWebinarRecord.link = data.link
      this.formWebinarRecord.password = data.password
      this.formWebinarRecord.expired_at = data.expired_at
      this.formWebinarRecord.webinar_id = data.webinar_id
      if (data.link.includes('</iframe>')) {
        this.linkType = 'embed'
      } else {
        this.linkType = 'link'
      }
      this.showModalWebinarRecord = true
      this.errorDeadline = false
    },
    closeModalWebinarRecord() {
      this.webinarRecord = {}
      this.webinarRecordId = 0
      this.formWebinarRecord.name = ''
      this.formWebinarRecord.live_at = ''
      this.formWebinarRecord.link = ''
      this.formWebinarRecord.password = ''
      this.formWebinarRecord.expired_at = ''
      this.formWebinarRecord.webinar_id = 0
      this.linkType = 'embed'
      this.showModalWebinarRecord = false
      this.errorDeadline = false
    },
    async submitWebinarRecord() {
      if (this.checkForm) {
        this.formWebinarRecord.live_at = moment(
          this.formWebinarRecord.live_at
        ).format('YYYY-MM-DD HH:mm:00')
        if (this.formWebinarRecord.expired_at != '') {
          this.formWebinarRecord.expired_at = moment(
            this.formWebinarRecord.expired_at
          ).format('YYYY-MM-DD HH:mm:00')
        }
        const payload: any = {
          name: this.formWebinarRecord.name,
          link: this.formWebinarRecord.link,
          live_at: this.formWebinarRecord.live_at,
        }
        if (this.webinarRecordId == 0) {
          await ClassroomModel.createWebinarRecordMentor(
            this.classroomCode,
            payload
          )
            .then(res => {
              if (res.success) {
                this.closeModalWebinarRecord()
                this.getWebinarRecord()
                this.toast.success('Berhasil tambah rekaman webinar')
              } else {
                this.toast.error('Terjadi kesalahan')
              }
            })
            .catch(err => {
              this.closeModalWebinarRecord()
              this.toast.error(err.response.data.error[0].message)
            })
        } else {
          await ClassroomModel.updateWebinarRecordMentor(
            this.webinarRecordId,
            payload
          )
            .then(res => {
              if (res.success) {
                this.closeModalWebinarRecord()
                this.getWebinarRecord()
                this.toast.success('Berhasil update rekaman webinar')
              } else {
                this.toast.error('Terjadi kesalahan')
              }
            })
            .catch(err => {
              this.closeModalWebinarRecord()
              this.toast.error(err.response.data.error[0].message)
            })
        }
      }
    },
    showModalDeleteWebinarRecord(data: any) {
      this.webinarRecord = data
      this.webinarRecordId = data.id
      this.showDeleteWebinarRecord = true
    },
    closeDeleteWebinarRecord() {
      this.webinarRecord = {}
      this.webinarRecordId = 0
      this.showDeleteWebinarRecord = false
    },
    async deleteWebinarRecord() {
      await ClassroomModel.deleteWebinarRecordMentor(this.webinarRecordId)
        .then(res => {
          this.closeDeleteWebinarRecord()
          if (res.success) {
            this.getWebinarRecord()
            this.toast.success('Berhasil menghapus rekaman webinar')
          } else {
            this.toast.error('Terjadi kesalahan')
            this.closeDeleteWebinarRecord()
          }
        })
        .catch(err => {
          this.closeDeleteWebinarRecord()
          this.toast.error(err.response.data.errors[0].message)
        })
    },
    togglePasswordLink() {
      this.passwordLinkStatus = !this.passwordLinkStatus
    },
    closeDetailWebinarRecord() {
      this.showDetailWebinarRecord = false
    },
    closeModalStatus() {
      this.showModalStatus = false
    },
    copyPasswordWebinar() {
      const password = this.$refs.password as HTMLInputElement
      try {
        password.setAttribute('type', 'text')
        password.select()
        document.execCommand('copy')
        this.toast.success('Berhasil disalin')
      } catch (err) {
        this.toast.error('Oops, unable to copy')
      }
      password.setAttribute('type', 'hidden')
    },
  },
})
</script>

<style scoped>
.border-b-blue {
  border-bottom: 3px solid #009dff;
}
::v-deep(.p-column-title) {
  font-family: 'SolusiSans';
  font-weight: 700;
}
::v-deep(.p-paginator-current) {
  font-family: 'SolusiSans';
  font-weight: 600;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
  font-family: 'SolusiSans';
  font-weight: 500;
}

.max-h-85 {
  max-height: 85vh;
}

.h-fit-content {
  height: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
}

.border-dark-blue {
  border: 1px solid #00446f;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  border: 1px solid #009dff;
  color: #009dff;
  font-weight: 600;
  background-color: #fff;
}
/* ::v-deep(.p-paginator .p-paginator-first) {
  color: #009DFF;
}
::v-deep( .p-paginator .p-paginator-prev) {
  color: #009DFF;
}
::v-deep( .p-paginator .p-paginator-next) {
  color: #009DFF;
}
::v-deep(.p-paginator .p-paginator-last) {
  color: #009DFF;
} */
.btn-show {
  color: #212121;
  border: 1px solid #212121;
}
.btn-edit {
  color: #00446f;
  border: 1px solid #00446f;
}
.btn-delete {
  color: #de1306;
  border: 1px solid #de1306;
}
.btn-show:hover,
.btn-edit:hover,
.btn-delete:hover {
  color: #fff;
  transition-duration: 500ms;
}
.btn-show:hover {
  background-color: #212121;
}
.btn-edit:hover {
  background-color: #00446f;
}
.btn-delete:hover {
  background-color: #de1306;
}

::v-deep(.ck-editor .ck-content ul) {
  list-style-type: disc;
  list-style-position: inside;
}
::v-deep(.ck-editor .ck-content ol) {
  list-style-type: decimal;
  list-style-position: inside;
}
::v-deep(.ck-editor .ck-content ul ul, ol ul) {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}
::v-deep(.ck-editor .ck-content ol) {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}
::v-deep(.ck-editor .ck-content p) {
  margin-top: 0;
  margin-bottom: 8px;
}
::v-deep(.ck-content) {
  font-family: 'SolusiSans';
  min-height: 150px;
  overflow-y: auto;
}
::v-deep(.select-custom .vs__search::placeholder, .select-custom
    .vs__dropdown-toggle, .select-custom .vs__dropdown-menu) {
  /* background: #dfe5fb;
  border: none; */
  font-family: 'SolusiSans', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  /* color: #394066;
  text-transform: lowercase;
  font-variant: small-caps; */
}

::v-deep(.select-custom .vs__selected) {
  font-family: 'SolusiSans', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #212121;
}
::v-deep(.select-custom .vs__dropdown-option) {
  font-family: 'SolusiSans', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #212121;
}
::v-deep(.select-custom .vs__search) {
  font-family: 'SolusiSans', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #212121;
}

::v-deep(.select-custom .vs__dropdown-menu) {
  position: absolute;
  top: 2rem;
  right: 0;
  z-index: 999999 !important;
  animation-fill-mode: both;
  max-height: 200px;
}
</style>
